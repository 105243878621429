import { _once, AUTH_EVENT_NAME } from '../../utils';

function connectIntercom() {
  if (isIntercomAllowed()) {
    const APP_ID = 'ramp4aya';
    window.intercomSettings = {
      app_id: APP_ID,
      horizontal_padding: 16,
      vertical_padding: 12,
    };
    document.head.appendChild(document.createElement('style')).innerHTML =
      '.back-to-top {right: 84px!important;}';
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
}

let intercomObserver = new IntersectionObserver(entries => {
  if (!entries[0].isIntersecting) {
    _once(() => {
      connectIntercom();
    })();
  }
});

function isIntercomAllowed() {
  return !!document.getElementById('allow-intercom') && window.allowIntercom;
}

document.addEventListener(AUTH_EVENT_NAME, async () => {
  const orderForm = await document.querySelector('.sorder__container');
  if (orderForm) {
    const rectClient = orderForm.getBoundingClientRect();

    if (rectClient.y > 700) {
      _once(() => {
        connectIntercom();
      })();
    } else {
      intercomObserver.observe(document.querySelector('.sorder__container'));
    }
  } else {
    _once(() => {
      connectIntercom();
    })();
  }
});
