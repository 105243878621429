/* eslint-disable no-undef */
/* eslint space-before-blocks: 0, indent: 0, vars-on-top:0, quote-props: 0, no-var:0, quotes: 0, func-names:0, prefer-arrow-callback:0, no-inner-declarations:0 */
import { _on } from '../../../utils';

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('#video-modal')) {
    const body = document.querySelector('body');
    const modalWindow = document.querySelector('#video-modal');
    const videoWrapper = document.querySelector('.video__wrapper');

    /**
     * @type {HTMLIFrameElement} youtube video iframe element
     */
    const youtubeVideo = document.createElement('iframe');
    youtubeVideo.id = 'youtube-frame';
    youtubeVideo.width = '100%';
    youtubeVideo.height = '315';
    youtubeVideo.src = 'https://www.youtube.com/embed/iHLIAAl-ETI';
    youtubeVideo.title = 'EssayShark.com - About us';
    youtubeVideo.frameborder = '0';
    youtubeVideo.allow =
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
    youtubeVideo.allowfullscreen = true;

    /**
     * Open modal and append iframe
     */
    function openModal() {
      modalWindow.classList.add('modal-open');
      body.style = 'overflow:hidden;';
      videoWrapper.appendChild(youtubeVideo);
    }

    /**
     * Close modal and delete iframe
     */
    function closeModal() {
      videoWrapper.innerHTML = '';
      modalWindow.classList.remove('modal-open');
      body.style = 'overflow:scroll;';
    }

    _on('click', 'js--open-video-modal', openModal);
    _on('click', 'js--close-video-modal', closeModal);
  }
});
